import { render, staticRenderFns } from "./groupItem.vue?vue&type=template&id=d97a1a4e&scoped=true&"
import script from "./groupItem.vue?vue&type=script&lang=js&"
export * from "./groupItem.vue?vue&type=script&lang=js&"
import style0 from "./groupItem.vue?vue&type=style&index=0&lang=less&"
import style1 from "./groupItem.vue?vue&type=style&index=1&id=d97a1a4e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d97a1a4e",
  null
  
)

export default component.exports