<template>
  <div class="meet-list">
    <Replay style="margin-bottom: 16px;" :data="item" v-for="item in dataList" :key="item.id"></Replay>
  </div>
</template>

<script>
import Replay from '@/views/task/points/replay'
import { meetMemoRequest } from '@/api'

export default {
  components: {
    Replay
  },
  data () {
    return {
      dataList: []
    }
  },
  props: {
    meetId: {
      type: [Number, String],
      default: null
    },
    dataType: {
      type: String,
      default: null
    },
    dataId: {
      type: [Number, String],
      default: null
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    meetId () {
      this.loadData()
    },
    dataType () {
      this.loadData()
    },
    dataId () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      let parm = {
        meetId: this.meetId,
        dataId: this.dataId,
        dataType: this.dataType
      }
      let datas = await meetMemoRequest.get(parm)
      if (!this.dataId && !this.dataType) {
        datas = datas.filter(v => !v.dataId)
      }
      this.dataList = datas
    }
  }
}
</script>
