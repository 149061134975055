<template>
  <div class="group-item">
    <div class="group-info">
      <div class="group-info-title">{{groupInfo.name}}</div>
      <div class="group-info-content">
        <div class="group-info-c">
          <fm-echart :chart-option="chartOption" :chart-event="chartEvent"></fm-echart>
        </div>
        <div class="group-info-detail">
          <div class="status-item" :class="{'status-item-active': chooseStatus === statusItem}" @click="clickStatus(statusItem)" v-for="statusItem in statusTask" :key="statusItem.key">
            <div class="status-name" style="width: 162px;">
              <div class="dian" :style="{background: nameMap[statusItem.key].color}"></div>
              <div class="status-note" style="margin-right:25px;">{{statusItem.label}}</div>|
              <span class="status-note" style="margin-left:5px;margin-right:25px;">{{ statusItem.tasks.length}}个</span>
            </div>
            <div class="group-tasks" v-if="statusItem.datas && statusItem.datas.length > 0">
              <span>任务分布：</span>
              <div class="data-item" v-for="dataItem in statusItem.datas" :key="dataItem.info.key">
                {{dataItem.info.name + '/' + dataItem.tasks.length}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="group-user-detail" style="margin-top: 10px;" v-if="chooseStatus">
      <div class="status-name" style="width: 162px;margin-bottom: 10px;">
        <div class="dian" :style="{background: nameMap[chooseStatus.key].color}"></div>
        <div style="margin-right:25px;rgba(0,0,0,0.65);">{{chooseStatus.label}}</div>
      </div>
      <div class="task-item" v-for="statusItem in chooseStatus.datas" :key="statusItem.info.key">
        <div class="task-item-title">
          <div style="margin-right:25px;rgba(0,0,0,0.65);">{{statusItem.info.name}}</div>
        </div>
        <div class="task-item-table">
          <fm-table-new
            size="small"
            :stripe="false"
            @row-dbclick="goTaskDetail"
            border="row"
            :columns="columns"
            :data-list="statusItem.tasks"
          ></fm-table-new>
        </div>
      </div>
    </div>
    <div class="group-info-line" v-if="!chooseStatus"></div>
    <div class="group-user-info">
      <div class="user-info-item" @click="clickUser(userItem)" :class="{'user-info-item-active': chooseUser && userItem.userInfo.id === chooseUser.userInfo.id}" v-for="userItem in userTask" :key="userItem.userInfo.id">
        <div class="title">{{userItem.userInfo.name}}</div>
        <div class="status-name" v-for="statusItem in userItem.tasks" :key="statusItem.key">
          <div class="dian" :style="{background: nameMap[statusItem.key].color}"></div>
          <div style="margin-right:25px;rgba(0,0,0,0.65);">{{statusItem.label}}</div>|
          <span style="margin-left:5px;color:rgba(0,0,0,0.45);">{{ statusItem.tasks.length}}个</span>
        </div>
      </div>
    </div>
    <div class="group-user-detail" v-if="chooseUser">
      <div class="title">{{chooseUser.userInfo.name}}</div>
      <div class="task-item" v-for="statusItem in chooseUser.tasks" :key="statusItem.key">
        <div class="task-item-title">
          <div class="dian" :style="{background: nameMap[statusItem.key].color}"></div>
          <div style="margin-right:25px;rgba(0,0,0,0.65);">{{statusItem.label}}</div>
        </div>
        <div class="task-item-table">
          <fm-table-new
            size="small"
            :stripe="false"
            border="row"
            :columns="columns"
            @row-dbclick="goTaskDetail"
            :data-list="statusItem.tasks"
          ></fm-table-new>
        </div>
      </div>
      <div class="user-memo" v-if="meetId">
        <div class="bc-title">会议补充</div>
        <memo-list :ref="'memoList_' + chooseUser.userInfo.id" :meet-id="meetId" data-type="user" :data-id="chooseUser.userInfo.id"></memo-list>
        <memo v-if="chooseUser.userInfo.id === $store.getters.userInfo.id" :meet-id="meetId" @add-memo="$refs['memoList_' + chooseUser.userInfo.id].loadData()" data-type="user" :data-id="chooseUser.userInfo.id"></memo>
      </div>
    </div>
  </div>
</template>

<script>
import MemoList from './memoList'
import Memo from './memo'

function getOption (name, data) {
  return {
    title: {
      text: name,
      bottom: 'center',
      left: 'center',
      textStyle: {
        color: 'rgba(0,0,0,0.85)'
      }
    },
    series: [{
      name: name,
      type: 'pie',
      radius: ['55%', '75%'],
      left: 0,
      avoidLabelOverlap: false,
      label: {
        show: false
      },
      labelLine: {
        show: false
      },
      data: data
    }]
  }
}

export default {
  components: {
    MemoList,
    Memo
  },
  props: {
    meetId: {
      type: [String, Number]
    },
    groupInfo: {
      type: Object
    },
    tasks: {
      type: Object
    }
  },
  data () {
    return {
      chartEvent: [],
      chooseStatus: null,
      columns: [{
        title: '任务标题',
        field: 'title',
        width: 400
      },
      {
        title: '归属',
        field: 'dataLabel'
      },
      // {
      //   title: '状态',
      //   field: 'status'
      // },
      // {
      //   title: '完成时间',
      //   field: 'finishTime'
      // },
      {
        title: '周期',
        field: 'range',
        render: (h, row) => {
          return h('div', (row.assignTime ? row.assignTime.slice(0, 10) : '') + '至' + (row.endTime ? row.endTime.slice(0, 10) : ''))
        }
      }],
      chooseUser: null,
      nameMap: {
        planTasks: {
          name: '计划任务',
          color: '#094FFF'
        },
        finishTasks: {
          name: '完成任务',
          color: '#36CBCB'
        },
        delayTasks: {
          name: '延误任务',
          color: '#FAD337'
        },
        leaveBehindTasks: {
          name: '遗留任务',
          color: '#F04864'
        },
        followUpTasks: {
          name: '后期计划',
          color: '#975FE4'
        }
      }
    }
  },
  created () {
    this.chartEvent = [{
      type: 'mouseover',
      dealFun: (parm) => {
        this.clickStatus(this.statusTask[parm.dataIndex])
      }
    }]
  },
  computed: {
    chartOption () {
      let data = Object.keys(this.nameMap).map((v) => {
        return {
          value: this.tasks[v].length,
          name: this.nameMap[v].name,
          itemStyle: {
            color: this.nameMap[v].color
          }
        }
      })
      return getOption('任务分布', data)
    },
    statusTask () {
      // leaveBehindTasks 遗留任务 finishTasks 完成任务
      // delayTasks 延误任务 followUpTasks 计划任务 planTasks  后续计划任务 followUpTasks
      let data = [{
        key: 'planTasks',
        label: this.nameMap.planTasks.name,
        datas: this.getTask(this.tasks.planTasks),
        tasks: this.tasks.planTasks
      },
      {
        key: 'finishTasks',
        label: this.nameMap.finishTasks.name,
        datas: this.getTask(this.tasks.finishTasks),
        tasks: this.tasks.finishTasks
      },
      {
        key: 'delayTasks',
        label: this.nameMap.delayTasks.name,
        datas: this.getTask(this.tasks.delayTasks),
        tasks: this.tasks.delayTasks
      },
      {
        key: 'leaveBehindTasks',
        label: this.nameMap.leaveBehindTasks.name,
        datas: this.getTask(this.tasks.leaveBehindTasks),
        tasks: this.tasks.leaveBehindTasks
      },
      {
        key: 'followUpTasks',
        label: this.nameMap.followUpTasks.name,
        datas: this.getTask(this.tasks.followUpTasks),
        tasks: this.tasks.followUpTasks
      }]
      return data
    },
    userTask () {
      let dataMap = {}
      let needKeys = ['finishTasks', 'delayTasks', 'followUpTasks']
      needKeys.forEach((key) => {
        this.tasks[key].forEach((v) => {
          if (v.executeUserId && !dataMap[v.executeUserId]) {
            dataMap[v.executeUserId] = {
              userInfo: {
                id: v.executeUserId,
                name: v.executeUserName
              },
              tasks: [{
                key: 'finishTasks',
                label: this.nameMap.finishTasks.name,
                tasks: this.tasks.finishTasks.filter(v2 => v2.executeUserId === v.executeUserId)
              },
              {
                key: 'delayTasks',
                label: this.nameMap.delayTasks.name,
                tasks: this.tasks.delayTasks.filter(v2 => v2.executeUserId === v.executeUserId)
              },
              {
                key: 'followUpTasks',
                label: this.nameMap.followUpTasks.name,
                tasks: this.tasks.followUpTasks.filter(v2 => v2.executeUserId === v.executeUserId)
              }]
            }
          }
        })
      })
      return Object.keys(dataMap).map(v => dataMap[v])
    }
  },
  methods: {
    goTaskDetail (parm) {
      window.open(location.origin + '/#/task?taskId=' + parm.row.historyDataId)
    },
    clickUser (userItem) {
      if (this.chooseUser && this.chooseUser.userInfo.id === userItem.userInfo.id) {
        this.chooseUser = null
      } else {
        this.chooseUser = userItem
      }
    },
    clickStatus (statusItem) {
      if (this.chooseStatus && this.chooseStatus.key === statusItem.key) {
        this.chooseStatus = null
      } else {
        this.chooseStatus = statusItem
      }
    },
    getTask (data) {
      let dataMap = {}
      data.forEach((v) => {
        let key = v.dataId + '-' + v.dataType
        if (dataMap[key]) {
          dataMap[key].tasks.push(v)
        } else {
          dataMap[key] = {
            info: {
              key: key,
              dataId: v.dataId,
              dataType: v.dataType,
              name: v.dataLabel
            },
            tasks: [v]
          }
        }
      })
      let datas =  Object.keys(dataMap).map(v => dataMap[v])
      datas.sort((a, b) => b.tasks.length - a.tasks.length)
      return datas
    }
  }
}
</script>

<style lang="less">
.group-item {
  .fm-table-border-row .fm-table-head-cells {
    border-top: 0px !important;
  }
}
</style>

<style scoped lang="less">
.bc-title {
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
}
.group-item {
  .fm-table-border-row .fm-table-head-cells {
    border-top: 0px !important;
  }
}
.group-user-detail {
  padding: 0 19px 19px;
  .title {
    font-size: 16px;
    color: #000;
    line-height: 22px;
    margin-bottom: 9px;
  }
  .task-item {
    padding-bottom: 23px;
    .task-item-title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    .task-item-table {
      width: 100%;
      min-height: 5rem;
    }
  }
}
.user-info-item {
  width: 152px;
  height: 118px;
  color: rgba(0,0,0,0.65);
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,0.15);
  padding: 13px 0 13px 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  .title {
    color: #000;
    line-height: 22px;
  }
}
.group-user-info {
  display: flex;
  padding: 21px 16px;
  display: flex;
  flex-wrap: wrap;
}
.dian {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #000;
  margin-right: 8px;
}
.status-name {
  display: flex;
  align-items: center;
}
.user-info-item-active {
  border-color: rgba(0,0,0,0);
  box-shadow: 0 3px 6px 3px rgba(9, 64, 255, 0.1);
}
.user-info-item:hover {
  border-color: rgba(0,0,0,0);
  box-shadow: 0 3px 6px 3px rgba(9, 64, 255, 0.1);
}
.group-item {
  margin: 16px;
  border-radius: 8px;
  background: #fff;
}
.group-info-line {
  width: calc(100% - 50px);
  margin: 0 25px;
  height: 1px;
  background: rgba(0,0,0,0.15);
}
.group-info {
  .group-info-title {
    font-size: 16px;
    line-height: 22px;
    padding: 13px 16px;
    color: #000;
  }
  .group-info-content {
    height: 200px;
    display: flex;
    .group-info-c {
      height: 100%;
      width: 200px;
    }
  }
  .group-info-detail {
    margin: 2rem 2rem 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .status-item {
      display: flex;
      font-size: 14px;
      color: #707070;
      .group-tasks {
        display: flex;
        .data-item {
          margin-right: 30px;
        }
      }
      .status-note {
        color: rgba(0,0,0,0.65);
      }
    }
    .status-item-active {
      color: #2F5BEA;
      .status-note {
        color: #2F5BEA;
      }
    }
    .status-item:hover {
      color: #2F5BEA;
      .status-note {
        color: #2F5BEA;
      }
    }
  }
}
</style>