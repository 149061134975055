<template>
  <div class="meet-memo">
    <fm-input-new style="display: flex;" type="textarea" v-model="content" placeholder="在此输入记录内容"></fm-input-new>
    <fm-btn style="margin-top: 10px;" type="primary" v-loadingx="loading" @click="submit">发表</fm-btn>
  </div>
</template>

<script>
import { meetMemoRequest } from '@/api'
export default {
  props: {
    meetId: {
      type: [Number, String],
      default: null
    },
    dataType: {
      type: String,
      default: null
    },
    dataId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      content: null,
      loading: false
    }
  },
  methods: {
    async submit () {
      if (!this.content) {
        this.$notice.warning('请输入记录内容')
      } else {
        this.loading = true
        await meetMemoRequest.add({
          meetId: this.meetId,
          content: this.content,
          dataType: this.dataType,
          dataId: this.dataId,
          userId: this.$store.getters.userInfo.id
        })
        this.$emit('add-memo')
        this.content = null
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="less">
  .meet-memo {
    position: relative;
    padding-left: 20px;
    &::before {
        z-index: 1;
        left: -6px;
        top: 15px;
        color: #657180;
        font-family: 'iconfont';
        position: absolute;
        content: '\e609';
        display: block;
    }
  }
</style>

<style lang="less">
  .meet-memo {
    .fm-input-new.fm-textarea {
      &::before {
        transform: rotate(-45deg);
        position: absolute;
        top: 16px;
        content: '';
        left: -5px;
        display: block;
        width: 8px;
        height: 8px;
        border: 1px solid #EEE;
        border-right: none;
        border-bottom: none;
        background-color: #FFF;
        z-index: 1;
      }
    }
  }
</style>
