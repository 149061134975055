<template>
  <div class="meet-detail">
    <div class="meet-base-info" v-if="meet">
      <div class="title">{{meet.title + '/' + (meet.taskStartTime ? meet.taskStartTime.slice(0, 10) : '') + '-' + (meet.taskEndTime ? meet.taskEndTime.slice(0, 10) : '')}}</div>
      <div class="note">主持人：{{meet.directUserName}}</div>
      <div class="note">参与人：{{meet.userNames}}</div>
    </div>
    <div class="group-list">
      <group-item v-for="item in groups" :key="item.id" :meet-id="meetId" :group-info="item.groupInfo" :tasks="item"></group-item>
    </div>
    <div class="meet-memo-list" v-if="meetId">
      <div class="title">会议记录</div>
      <memo-list ref="memoList" :meet-id="meetId"></memo-list>
      <memo :meet-id="meetId" @add-memo="$refs.memoList.loadData()"></memo>
    </div>
  </div>
</template>

<script>
import GroupItem from './detail/groupItem'
import MemoList from './detail/memoList'
import Memo from './detail/memo'

import {
  meetRequest as request,
  taskRequest
} from '@/api'

import {
  getDataLabel
} from '@/views/task/lib'

export default {
  components: {
    GroupItem,
    MemoList,
    Memo
  },
  data () {
    return {
      meetId: null,
      meet: null,
      groups: []
    }
  },
  computed: {
  },
  created () {
    this.meetId = this.$router.history.current.query ? this.$router.history.current.query.id :null
    this.loadData()
  },
  methods: {
    async dealData (data) {
      let userMap = {}
      let userList = await this.$store.dispatch('loadUserList')
      userList.forEach((v) => {
        userMap[v.key] = v.label
      })
      data.forEach((v) => {
        v.directUserName = userMap[v.directUserId]
        v.taskDateRange = [v.taskStartTime, v.taskEndTime]
        v.userIds = v.userIds ? v.userIds.split(',').map(v2 => Number(v2)) : []
        v.userNames = v.userIds.map(v2 => userMap[v2]).join('，')
      })
      return data
    },
    async loadData () {
      if (!this.meetId) {
        return
      }
      let meets = await request.get({id: this.meetId})
      meets = await this.dealData(meets)
      this.meet = meets && meets.length > 0 ? meets[0] : null
      if (this.meet) {
        this.loadTask()
      }
    },
    async loadTask () {
      let parm = {
        date: this.meet.taskTime,
        startTime: this.meet.taskStartTime,
        endTime: this.meet.taskEndTime,
      }
      let datas = await taskRequest.getHis(parm)
      let groupMap = {}
      for (let key in datas) {
        let d1 = datas[key].filter(v => v.assignTime)
        let d2 = datas[key].filter(v => !v.assignTime)
        d1.sort((a, b) => {
          let t1 = a.endTime ? new Date(a.endTime).getTime() : 0
          let t2 = b.endTime ? new Date(b.endTime).getTime() : 0
          return t1 - t2
        })
        d1.sort((a, b) => {
          let t1 = a.assignTime ? new Date(a.assignTime).getTime() : 0
          let t2 = b.assignTime ? new Date(b.assignTime).getTime() : 0
          return t1 - t2
        })
        d2.sort((a, b) => {
          let t1 = a.createTime ? new Date(a.createTime).getTime() : 0
          let t2 = b.createTime ? new Date(b.createTime).getTime() : 0
          return t1 - t2
        })
        datas[key] = d1.concat(d2)
        datas[key].forEach((v) => {
          v.executeUserRoles = v.executeUserInfo.roles
          v.createUserRoles = v.createUserInfo.roles
          v.belongGroup = v.executeUserRoles && v.executeUserRoles.length > 0 ? v.executeUserRoles[0] : (v.createUserRoles && v.createUserRoles.length > 0 ? v.createUserRoles[0] : [{id: 'other', name: '其他'}])
          v.dataLabel = getDataLabel(v)
          groupMap[v.belongGroup.id] = {
            groupInfo: v.belongGroup
          }
        })
      }
      // leaveBehindTasks 遗留任务 finishTasks 完成任务 delayTasks
      // 延误任务 followUpTasks 计划任务 planTasks  后续计划任务 followUpTasks
      for (let key in groupMap) {
        for (let status in datas) {
          groupMap[key][status] = datas[status].filter(v => v.belongGroup.id === Number(key))
        }
      }
      this.groups = Object.keys(groupMap).map(v => groupMap[v])
      this.groups.sort((a, b) => b.groupInfo.id - a.groupInfo.id)
    }
  }
}
</script>

<style scoped lang="less">
.meet-detail {
  width: 100%;
  height: 100%;
}
.meet-base-info {
  font-size: 16px;
  color: #707070;
  line-height: 35px;
  background: #fff;
  padding: 0 23px 10px;
  .title {
    line-height: 22px;
    padding: 16px 0 8px;
    color: #2F5BEA;
  }
}
.meet-memo-list {
  padding: 0 20px 20px 26px;
  background: #fff;
  border-radius: 8px;
  margin: 16px;
  .title {
    line-height: 25px;
    color: #000;
    padding: 11px 0;
    margin-left: -6px;
  }
}
</style>